import React, { useState } from "react";
import { MdUpload } from "react-icons/md";

// Utility function to format date as YYYY-MM-DD
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Months are zero-based
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

function Contact() {
  // Initialize form data state
  const [formData, setFormData] = useState({
    voornaam: "",
    achternaam: "",
    telefoonnummer: "",
    postcode: "",
    email: "",
    woonplaats: "",
    typeKlus: "",
    vrijeTekst: "",
    cv: null, // Renamed from pdfFile to cv for consistency
    gewensteDatum: "", // Desired date for klus execution
    kennismakingDatum: "", // Date for kennismaking
  });

  // State for file name display
  const [pdfFileName, setPdfFileName] = useState("");

  // State for response message after form submission
  const [responseMessage, setResponseMessage] = useState("");

  // State for validation errors
  const [errors, setErrors] = useState({});

  // Regex patterns for validation
  const phonePattern = /^\+\d{1,3}\d{4,14}$/; // Example: +31xxxxxx
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const postcodePattern = /^\d{4}$/; // Exactly 4 digits

  // Calculate today's date and one month from today
  const today = new Date();
  const minDate = formatDate(today);

  const oneMonthFromToday = new Date();
  oneMonthFromToday.setMonth(oneMonthFromToday.getMonth() + 1);
  const maxDate = formatDate(oneMonthFromToday);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, cv: file }));
    setPdfFileName(file ? file.name : "");
  };

  // Handle field blur for immediate validation
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  // Validate individual fields
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "telefoonnummer":
        if (!phonePattern.test(value)) {
          error = "Ongeldig telefoonnummer. Gebruik het formaat: +31xxxxxx";
        }
        break;
      case "email":
        if (!emailPattern.test(value)) {
          error = "Ongeldig e-mail formaat.";
        }
        break;
      case "gewensteDatum":
        if (!value) {
          error = "Selecteer een gewenste datum voor klus uitvoering.";
        } else {
          const selectedDate = new Date(value);
          const todayDate = new Date();
          todayDate.setHours(0, 0, 0, 0);
          const oneMonthDate = new Date();
          oneMonthDate.setMonth(oneMonthDate.getMonth() + 1);
          oneMonthDate.setHours(0, 0, 0, 0);

          if (selectedDate < todayDate) {
            error = "De gewenste datum kan niet in het verleden liggen.";
          } else if (selectedDate > oneMonthDate) {
            error =
              "De gewenste datum kan niet meer dan een maand in de toekomst liggen.";
          }
        }
        break;
      case "kennismakingDatum":
        if (!value) {
          error = "Selecteer een datum voor kennismaking.";
        } else {
          const selectedDate = new Date(value);
          const todayDate = new Date();
          todayDate.setHours(0, 0, 0, 0);

          if (selectedDate < todayDate) {
            error = "De kennismaking datum kan niet in het verleden liggen.";
          }
        }
        break;
      case "postcode":
        if (!postcodePattern.test(value)) {
          error =
            "Ongeldig postcode formaat. Gebruik exact 4 cijfers, bijvoorbeeld: 4577";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  // Validate the entire form before submission
  const validateForm = () => {
    const tempErrors = {};

    // Validate telefoonnummer
    if (!phonePattern.test(formData.telefoonnummer)) {
      tempErrors.telefoonnummer =
        "Ongeldig telefoonnummer. Gebruik het formaat: +31xxxxxx";
    }

    // Validate email
    if (!emailPattern.test(formData.email)) {
      tempErrors.email = "Ongeldig e-mail formaat.";
    }

    // Validate gewensteDatum
    if (!formData.gewensteDatum) {
      tempErrors.gewensteDatum =
        "Selecteer een gewenste datum voor klus uitvoering.";
    } else {
      const gewensteDatum = new Date(formData.gewensteDatum);
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      const oneMonthFromTodayDate = new Date();
      oneMonthFromTodayDate.setMonth(oneMonthFromTodayDate.getMonth() + 1);
      oneMonthFromTodayDate.setHours(0, 0, 0, 0);

      if (gewensteDatum < todayDate) {
        tempErrors.gewensteDatum =
          "De gewenste datum kan niet in het verleden liggen.";
      } else if (gewensteDatum > oneMonthFromTodayDate) {
        tempErrors.gewensteDatum =
          "De gewenste datum kan niet meer dan een maand in de toekomst liggen.";
      }
    }

    // Validate kennismakingDatum
    if (!formData.kennismakingDatum) {
      tempErrors.kennismakingDatum = "Selecteer een datum voor kennismaking.";
    } else {
      const kennismakingDatum = new Date(formData.kennismakingDatum);
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);

      if (kennismakingDatum < todayDate) {
        tempErrors.kennismakingDatum =
          "De kennismaking datum kan niet in het verleden liggen.";
      }
    }

    // Validate postcode
    if (!postcodePattern.test(formData.postcode)) {
      tempErrors.postcode =
        "Ongeldig postcode formaat. Gebruik exact 4 cijfers, bijvoorbeeld: 4577";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setResponseMessage("Please fix the errors in the form.");
      return;
    }

    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "cv" && value) {
        data.append("cv", value); // Ensure the key matches server expectations
      } else {
        data.append(key, value);
      }
    });

    try {
      const response = await fetch(
        "https://us-central1-email-itsww-cedb9.cloudfunctions.net/applyJob4",
        {
          method: "POST",
          body: data,
        }
      );

      if (response.ok) {
        setResponseMessage("Email succesvol verzonden!");

        // Reset form after successful submission
        setFormData({
          voornaam: "",
          achternaam: "",
          telefoonnummer: "",
          postcode: "",
          email: "",
          woonplaats: "",
          typeKlus: "",
          vrijeTekst: "",
          cv: null, // Reset cv field
          gewensteDatum: "",
          kennismakingDatum: "",
        });
        setPdfFileName("");
        setErrors({});

        // Optionally, reset the file input by changing its key
        setFileInputKey((prevKey) => prevKey + 1);
      } else {
        const errorText = await response.text();
        setResponseMessage(
          `Het versturen van de email is mislukt: ${errorText}`
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponseMessage(
        "Er is een fout opgetreden bij het versturen van de email."
      );
    }
  };

  // State to force re-render of file input for resetting
  const [fileInputKey, setFileInputKey] = useState(0);

  return (
    <div className="flex justify-center items-center p-5">
      <form className="w-full max-w-4xl" onSubmit={handleSubmit} noValidate>
        <div className="border-4 border-[#284B5A] rounded-lg p-6">
          <div className="flex flex-wrap gap-5">
            {/* Left Column: Personal and Job Details */}
            <div className="flex flex-col w-full lg:w-6/12">
              {/* Personal Information */}
              <div className="flex flex-col lg:flex-row gap-5 mb-5">
                {/* Voornaam */}
                <div className="w-full lg:w-1/2">
                  <label
                    htmlFor="voornaam"
                    className="block text-xl font-bold text-slate-500"
                  >
                    Voornaam
                  </label>
                  <input
                    type="text"
                    id="voornaam"
                    name="voornaam"
                    value={formData.voornaam}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                  />
                </div>

                {/* Achternaam */}
                <div className="w-full lg:w-1/2">
                  <label
                    htmlFor="achternaam"
                    className="block text-xl font-bold text-slate-500"
                  >
                    Achternaam
                  </label>
                  <input
                    type="text"
                    id="achternaam"
                    name="achternaam"
                    value={formData.achternaam}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                  />
                </div>
              </div>

              {/* Contact Information */}
              <div className="flex flex-col lg:flex-row gap-5 mb-5">
                {/* Telefoonnummer */}
                <div className="w-full lg:w-1/2">
                  <label
                    htmlFor="telefoonnummer"
                    className="block text-xl font-bold text-slate-500"
                  >
                    Telefoonnummer
                  </label>
                  <input
                    type="tel"
                    id="telefoonnummer"
                    name="telefoonnummer"
                    value={formData.telefoonnummer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`mt-2 w-full bg-white rounded-xl border ${
                      errors.telefoonnummer
                        ? "border-red-500"
                        : "border-slate-700"
                    } h-[68px] p-2`}
                  />
                  {errors.telefoonnummer && (
                    <p
                      className="text-red-500 text-sm mt-1"
                      role="alert"
                      aria-live="assertive"
                    >
                      {errors.telefoonnummer}
                    </p>
                  )}
                </div>

                {/* Postcode */}
                <div className="w-full lg:w-1/2">
                  <label
                    htmlFor="postcode"
                    className="block text-xl font-bold text-slate-500"
                  >
                    Postcode
                  </label>
                  <input
                    type="number"
                    id="postcode"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min="1000"
                    max="9999"
                    className={`mt-2 w-full bg-white rounded-xl border ${
                      errors.postcode ? "border-red-500" : "border-slate-700"
                    } h-[68px] p-2`}
                  />
                  {errors.postcode && (
                    <p
                      className="text-red-500 text-sm mt-1"
                      role="alert"
                      aria-live="assertive"
                    >
                      {errors.postcode}
                    </p>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="block text-xl font-bold text-slate-500"
                >
                  E-mail adres
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`mt-2 w-full bg-white rounded-xl border ${
                    errors.email ? "border-red-500" : "border-slate-700"
                  } h-[68px] p-2`}
                />
                {errors.email && (
                  <p
                    className="text-red-500 text-sm mt-1"
                    role="alert"
                    aria-live="assertive"
                  >
                    {errors.email}
                  </p>
                )}
              </div>

              {/* Woonplaats */}
              <div className="mb-5">
                <label
                  htmlFor="woonplaats"
                  className="block text-xl font-bold text-slate-500"
                >
                  Woonplaats
                </label>
                <input
                  type="text"
                  id="woonplaats"
                  name="woonplaats"
                  value={formData.woonplaats}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                />
              </div>

              {/* Type Klus */}
              <div className="mb-5">
                <label
                  htmlFor="typeKlus"
                  className="block text-xl font-bold text-slate-500"
                >
                  Type klus:
                </label>
                <input
                  type="text"
                  id="typeKlus"
                  name="typeKlus"
                  value={formData.typeKlus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2"
                />
              </div>

              {/* Vrije Tekst */}
              <div>
                <label
                  htmlFor="vrijeTekst"
                  className="block text-xl font-bold text-slate-500"
                >
                  Vrije tekst met wensen en eventuele opmerkingen
                </label>
                <textarea
                  id="vrijeTekst"
                  name="vrijeTekst"
                  value={formData.vrijeTekst}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[196px] p-2"
                />
              </div>
            </div>

            {/* Right Column: Dates and File Upload */}
            <div className="flex flex-col w-full lg:w-5/12 lg:mt-60 lg:ml-auto lg:items-center">
              {/* Desired Date Selection */}
              <div className="mb-5 w-full">
                <label
                  htmlFor="gewensteDatum"
                  className="block text-xl font-bold text-slate-500"
                >
                  Wanneer wilt u de klus laten uitvoeren?
                </label>
                <input
                  type="date"
                  id="gewensteDatum"
                  name="gewensteDatum"
                  value={formData.gewensteDatum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={minDate}
                  max={maxDate}
                  className={`mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2 ${
                    errors.gewensteDatum ? "border-red-500" : ""
                  }`}
                />
                {errors.gewensteDatum && (
                  <p
                    className="text-red-500 text-sm mt-1"
                    role="alert"
                    aria-live="assertive"
                  >
                    {errors.gewensteDatum}
                  </p>
                )}
              </div>

              {/* Kennismaking Date Selection */}
              <div className="mb-5 w-full">
                <label
                  htmlFor="kennismakingDatum"
                  className="block text-xl font-bold text-slate-500"
                >
                  Welke dag komt u het beste uit om onze collega
                  <br /> langs te sturen voor kennismaking en opname van de
                  klus?
                </label>
                <input
                  type="date"
                  id="kennismakingDatum"
                  name="kennismakingDatum"
                  value={formData.kennismakingDatum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min={minDate}
                  max={maxDate} // Optional: You might want to remove max for kennismakingDatum
                  className={`mt-2 w-full bg-white rounded-xl border border-solid border-slate-700 h-[68px] p-2 ${
                    errors.kennismakingDatum ? "border-red-500" : ""
                  }`}
                />
                {errors.kennismakingDatum && (
                  <p
                    className="text-red-500 text-sm mt-1"
                    role="alert"
                    aria-live="assertive"
                  >
                    {errors.kennismakingDatum}
                  </p>
                )}
              </div>

              {/* File Upload Section */}
              <div className="text-xl font-bold text-center text-slate-500 mb-4">
                Uploaden eventuele tekeningen, handleidingen
              </div>
              <div className="relative w-full">
                {/* Accessibility Improvement: Visually hidden label */}
                <label htmlFor="cv" className="sr-only">
                  Upload File
                </label>
                <input
                  key={fileInputKey} // Added key for resetting
                  type="file"
                  id="cv"
                  name="cv"
                  accept=".pdf, .doc, .docx, .png, .jpg, .jpeg" // Updated accept attribute
                  onChange={handleFileChange}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                <div className="bg-white rounded-xl border border-dashed border-slate-700 h-[223px] w-full p-2 flex items-center justify-center cursor-pointer">
                  <MdUpload className="text-4xl text-slate-500" />
                </div>
                <div className="text-xl font-bold text-slate-500 mt-2">
                  {pdfFileName
                    ? `Geselecteerd bestand: ${pdfFileName}`
                    : "Geen bestand geselecteerd"}
                </div>
              </div>
            </div>
          </div>

          {/* Response Message */}
          {responseMessage && (
            <p
              className="mt-4 text-center text-slate-500"
              role="status"
              aria-live="polite"
            >
              {responseMessage}
            </p>
          )}

          {/* Submit Button */}
          <div className="flex justify-center mt-5">
            <button
              type="submit"
              className="bg-[#284B5A] text-white px-5 py-3 rounded-lg hover:bg-[#1d3a45] transition-colors duration-300"
            >
              Versturen
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Contact;
