import React from "react";

function LearnMore() {
  return (
    <div className="bg-[#D9D9D9] p-4 text-center">
      <p>
        Bezoek deze URL voor meer informatie over ons team:{" "}
        <a
          href="https://deploegopderoot.be/"
          className="text-blue-600 hover:underline"
        >
          https://deploegopderoot.be/
        </a>
      </p>
    </div>
  );
}

export default LearnMore;
