import React from "react";
import { motion } from "framer-motion";
import ImageUrl1 from "../../assets/images/It Solutions World Wide.png";
import ImageUrl2 from "../../assets/images/Logistic Recruiters.png";
import ImageUrl3 from "../../assets/images/Mateck Project Support.png";
import ImageUrl4 from "../../assets/images/Nexxt It.png";
import ImageUrl5 from "../../assets/images/Tanks.png";
import ImageUrl6 from "../../assets/images/Terminal Recruiters.png";
import ImageUrl7 from "../../assets/images/Top Result.png";
import ImageUrl8 from "../../assets/images/The Knowledge.png";
const logos = [
  ImageUrl2,
  ImageUrl3,
  ImageUrl4,
  ImageUrl1,
  ImageUrl5,
  ImageUrl6,
  ImageUrl7,
  ImageUrl8,
];

const LogoSlider = () => {
  return (
    <div
      className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-8 py-12"
      style={{
        background:
          "linear-gradient(9deg, rgba(40, 75, 90, 0.20) -2.93%, rgba(89, 161, 193, 0.20) 93.32%)",
      }}
    >
      <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold w-full lg:w-1/2 ml-0 lg:ml-8 text-[#284b5a] text-center lg:text-left">
        Partners
      </h2>
      <div className="overflow-hidden relative w-full">
        <motion.div
          className="flex space-x-8 animate-marquee"
          animate={{ x: ["0%", "-100%"] }}
          transition={{
            repeat: Infinity,
            ease: "linear",
            duration: 1, // Faster speed with shorter duration
          }}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Partner logo ${index + 1}`}
              className="w-24 h-24 md:w-36 md:h-36 lg:w-48 lg:h-48 object-contain"
            />
          ))}
          {/* Duplicate logos to create a seamless loop */}
          {logos.map((logo, index) => (
            <img
              key={index + logos.length}
              src={logo}
              alt={`Partner logo duplicate ${index + 1}`}
              className="w-24 h-24 md:w-36 md:h-36 lg:w-48 lg:h-48 object-contain"
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default LogoSlider;
