import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText5 from "../../components/ImageText5";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/Opleidingen Background Image.png";
import imageUrl1 from "../../assets/images/OPLEIDINGEN 1.png";
import imageUrl2 from "../../assets/images/OPLEIDINGEN 2.png";
import imageUrl3 from "../../assets/images/OPLEIDINGEN 3.png";
import imageUrl4 from "../../assets/images/OPLEIDINGEN 4.png";
import logoUrl from "../../assets/images/The Knowledge.png";
function Opleidingen() {
  const welcomeHeading = "OPLEIDINGEN";
  const imageheading1 = "De opleider voor intern transport en veiligheid";
  const imageTextText = (
    <>
      DD-Group biedt niet alleen via Play-IT diverse opleidingen aan, varierend
      van veiligheidstrainingen, brandpreventie en cyberbeveiliging maar ook via
      haar gelieerde partner "The Knowledge" kunt u bij ons terecht voor intern
      transport- en veiligheidsopleidingen.
    </>
  );

  const imageTextText3 = (
    <>
      <span style={{ fontWeight: "bold" }}>
        Mocht u meer informatie willen neemt u dan contact met ons op!
      </span>
      <br />
      <br />
      Wij zullen uw vragen en of wensen doorzetten naar "The Knowledge" met ons
      unieke kenmerk. Hierna zal "The Knowledge" direct met u contact opnemen
      voor verdere afstemming. Door het unieke kenmerk krijgt u gegarandeerd van
      "The Knowledge" de scherpst mogelijke aanbieding.
    </>
  );

  const backgroundColor = "#D9D9D9";
  const textColor = "black";
  const buttonText = "Neem contact met ons op";
  const buttonLink1 = "/Inschrijven";
  return (
    <div>
      <Helmet>
        <title>Opleidingen</title>
        <meta
          name="description"
          content="Krijg training met DD-Group over veiligheid, brandpreventie en cybersecurity via Play-IT. Voor interne transport- en veiligheidstrainingen kun je contact met ons opnemen, en wij verbinden je met 'The Knowledge' voor het beste aanbod via onze speciale referentie."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageText5
            imageUrl1={imageUrl1}
            imageUrl2={imageUrl2}
            imageUrl3={imageUrl3}
            heading={imageheading1}
            text={imageTextText}
            logoUrl={logoUrl}
          />

          <ImageText1
            imageUrl={imageUrl4}
            text={imageTextText3}
            backgroundColor={backgroundColor}
            textColor={textColor}
            buttonText={buttonText}
            buttonLink={buttonLink1}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Opleidingen;
