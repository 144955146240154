import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { ChevronRightIcon } from "@heroicons/react/20/solid";

const ImageText6 = ({
  imageUrl1,
  imageUrl2,
  imageUrl3,
  heading,
  text,
  buttonText,
  buttonLink,
  backgroundColor = "#284B5A",
  textColor = "#fff",
  buttonColor = "#fff",
  buttonTextColor = "#284B5A",
  logoUrl,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div style={{ backgroundColor }}>
      <div
        className="mx-auto py-12 px-4 sm:px-6 lg:px-8"
        style={{ maxWidth: "1310px" }}
        ref={ref}
      >
        <div className="relative flex flex-col lg:flex-row items-center lg:items-start lg:space-x-12">
          {/* Text and Button */}
          <div className="relative w-full lg:w-1/2 flex flex-col items-center text-left lg:pt-12 lg:text-left lg:mt-0">
            <motion.div
              className="text-left lg:text-left"
              initial={{ x: -100, opacity: 0 }}
              animate={inView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <h2
                className="text-3xl lg:text-3xl font-semibold mb-4"
                style={{ color: textColor, fontWeight: "bold" }}
              >
                {heading}
              </h2>
              <p className="text-lg lg:text-lg" style={{ color: textColor }}>
                {text}
              </p>
              {buttonText && (
                <a
                  href={buttonLink}
                  className="inline-flex items-center no-underline mt-4 px-6 py-2 text-lg font-semibold rounded-lg shadow-md"
                  style={{
                    color: buttonTextColor,
                    backgroundColor: buttonColor,
                  }}
                >
                  {buttonText}
                  <ChevronRightIcon
                    className="ml-2 w-5 h-5"
                    style={{ color: buttonTextColor }}
                  />
                </a>
              )}
              {logoUrl && (
                <div className="mt-4">
                  <img src={logoUrl} alt="logo" className="w-[360px] h-auto" />
                </div>
              )}
            </motion.div>
          </div>

          {/* Container for Middle Image with Top and Bottom Images on the Right */}
          <div className="relative w-full lg:w-1/2 flex lg:items-start">
            {/* Middle Image */}
            <motion.div
              className="w-full lg:w-1/2 z-40 relative"
              style={{ top: "140px", left: "80px" }}
              initial={{ x: -100, opacity: 0 }}
              animate={inView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 0.5 }}
            >
              <img
                src={imageUrl3}
                alt="Middle"
                className="w-full h-auto lg:w-full sm:w-3/4"
              />
            </motion.div>

            {/* Container for Top and Bottom Images */}
            <div className="flex flex-col relative">
              {/* Top Image */}
              <motion.div
                className="relative z-20"
                style={{ marginBottom: "-40px" }}
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={imageUrl1}
                  alt="Top"
                  className="w-full h-auto lg:w-full sm:w-3/4"
                />
              </motion.div>

              {/* Bottom Image */}
              <motion.div
                className="relative z-30"
                style={{ marginTop: "-40px" }}
                initial={{ x: 100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={imageUrl2}
                  alt="Bottom"
                  className="w-full h-auto lg:w-full sm:w-3/4"
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageText6;
