import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage from "../../components/WelcomeImage";
import ImageText1 from "../../components/ImageText1";
import ImageText2 from "../../components/ImageText2";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/Detachering en Werving & Selectie Background Image.png";
import imageUrl from "../../assets/images/DETACHERING EN WERVING & SELECTIE 1.png";
import imageUrl2 from "../../assets/images/DETACHERING EN WERVING & SELECTIE 2.png";
import imageUrl3 from "../../assets/images/DETACHERING EN WERVING & SELECTIE 3.png";

function Detachering() {
  const welcomeHeading = "DETACHERING EN WERVING & SELECTIE";

  const imageTextText = (
    <>
      Ons werving- en selectieproces is transparant en flexibel. Directe werving
      & selectie, of in combinatie met een voorafgaande detacherings-periode,
      alles is mogelijk. Bent U voor een kortere periode op zoek naar een
      Interim Professional ook dat is mogelijk. Wij beschikken over een
      uitgebreide database van professionals die u kunnen bijstaan voor een
      korte of middellange periode. Bent u op zoek naar Commissioning engineers,
      Procurement Leads, HR-managers of Project managers.
      <br />
      <br />
      wij ontzorgen u graag!
    </>
  );

  const imageTextText2 = (
    <>
      Bent u op zoek naar de juiste kandidaat voor een positie binnen uw
      bedrijf, wij kunnen u hierbij helpen. Wij beschikken over een uitgebreide
      database van specialisten, variërend van recent afgestudeerde kandidaten
      tot ervaren krachten. Of het nu gaat om een productiemedewerker,
      pijpfitter, mechanical supervisor of procurement lead wij vinden de
      geschikte kandidaat voor U.
    </>
  );

  const imageTextText3 = (
    <>
      <span style={{ fontWeight: "bold" }}>
        De kracht van de juiste mensen op de juiste plek
      </span>
      <br />
      <br />
      Voelt u zich geroepen om samen met ons die stap te willen maken in uw
      carrière en te werken aan een nieuwe toekomst,
      <br />
      <br />
      Seld je dan bij ons aan middels het inschrijfformulier en upload uw CV
      vandaag nog!
    </>
  );

  // Declare colors as variables
  const backgroundColor = "#D9D9D9";
  const textColor = "black";

  const buttonText = "CV UPLOADEN";
  const buttonLink1 = "/Inschrijven";
  return (
    <div>
      <Helmet>
        <title>Detachering</title>
        <meta
          name="description"
          content="Heb je vakbekwame professionals nodig voor korte of lange termijn rollen? Ons duidelijke en flexibele wervingsproces helpt je de juiste kandidaat te vinden, of ze nu recent afgestudeerd zijn of ervaren specialisten."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <ImageText1 imageUrl={imageUrl} text={imageTextText} />
          <ImageText2
            imageUrl={imageUrl2}
            text={imageTextText2}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
          <ImageText1
            imageUrl={imageUrl3}
            text={imageTextText3}
            backgroundColor={backgroundColor}
            textColor={textColor}
            buttonText={buttonText}
            buttonLink={buttonLink1}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Detachering;
