import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { motion } from "framer-motion";
import logo from "../assets/images/Logo 2.png"; // Update path as needed
import filedown from "../assets/files/Business Code of Conduct.pdf";
function CustomNavbar() {
  return (
    <div className="absolute top-0 left-0 w-full z-20 bg-transparent">
      <Navbar expand="lg" className="bg-transparent">
        <Container>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Navbar.Brand href="/" className="mx-auto">
              <img
                src={logo}
                alt="Logo"
                className="w-52 h-12" // Adjust size as needed
              />
            </Navbar.Brand>
          </motion.div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto md:gap-6">
              {[
                "SECTOREN",
                "DIENSTEN",
                "OVER ONS",
                "NIEUWS",
                "INSCHRIJVEN",
              ].map((title, index) => (
                <motion.div
                  key={title}
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  <NavDropdown
                    title={<span className="text-black">{title}</span>}
                    id={`basic-nav-dropdown-${index}`}
                    className="text-black"
                  >
                    {getDropdownItems(title)}
                  </NavDropdown>
                </motion.div>
              ))}
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Nav.Link href="/Partners" className="text-black">
                  PARTNERS
                </Nav.Link>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Nav.Link
                  href={filedown} // Set the path to your file
                  download // Add the download attribute to trigger download
                  className="text-black"
                >
                  BUSINESS CODE OF CONDUCT
                </Nav.Link>
              </motion.div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Fullscreen menu for mobile */}
      <style jsx>{`
        @media (max-width: 991.98px) {
          .navbar-collapse {
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-color: #fff; /* Background color of full-screen menu */
            z-index: 9999;
            padding-top: 3rem;
            padding-bottom: 3rem;
            overflow-y: auto;
          }
          .navbar-nav {
            margin: auto;
            text-align: center;
          }
          .nav-item {
            margin: 1rem 0; /* Adjust spacing between items */
          }
        }
        .navbar-toggler {
          border: none;
        }
        .navbar-toggler-icon {
          background-image: none;
        }
        .navbar-toggler-icon:before {
          content: "\\2630"; /* Unicode for hamburger icon */
          font-size: 1.5rem;
          color: black; /* Change color to black */
        }
        .absolute {
          position: absolute;
        }
        .top-4 {
          top: 1rem;
        }
        .right-4 {
          right: 1rem;
        }
      `}</style>
    </div>
  );

  function getDropdownItems(title) {
    switch (title) {
      case "SECTOREN":
        return (
          <>
            <NavDropdown.Item href="/Bouw-Infra">Bouw & Infra</NavDropdown.Item>
            <NavDropdown.Item href="/Scheepvaart">Scheepvaart</NavDropdown.Item>
            <NavDropdown.Item href="/Industrie">Industrie</NavDropdown.Item>
            <NavDropdown.Item href="/InkoopLogistiek">
              Inkoop & Logistiek
            </NavDropdown.Item>
          </>
        );
      case "DIENSTEN":
        return (
          <>
            <NavDropdown.Item href="/Play-IT">Play-IT</NavDropdown.Item>
            <NavDropdown.Item href="/Opleidingen">Opleidingen</NavDropdown.Item>
            <NavDropdown.Item href="/Detachering-En-Werving-&-Selectie">
              Detachering en Werving & Selectie
            </NavDropdown.Item>
            <NavDropdown.Item href="/Klus-&-Tuin">Klus & Tuin</NavDropdown.Item>
          </>
        );
      case "OVER ONS":
        return (
          <>
            <NavDropdown.Item href="/Wie-zijn-Wij">
              Wie Zijn Wij
            </NavDropdown.Item>
            <NavDropdown.Item href="/MVO-Sponsoring">
              MVO/Sponsoring
            </NavDropdown.Item>
            <NavDropdown.Item href="/VCU/VCA/NEN-Certificering">
              VCU / VCA / NEN certificering
            </NavDropdown.Item>
          </>
        );
      case "NIEUWS":
        return (
          <>
            <NavDropdown.Item href="/Social-Media-Link">
              Social Media Link
            </NavDropdown.Item>
            <NavDropdown.Item href="/Ploeg-Op-De-Root">
              Ploeg Op De Root
            </NavDropdown.Item>
            <NavDropdown.Item href="/Business-Club">
              Business Club
            </NavDropdown.Item>
          </>
        );
      case "INSCHRIJVEN":
        return (
          <>
            <NavDropdown.Item href="/Inschrijven">
              Formulier Om In Te Schrijven En CV Op Te Laden
            </NavDropdown.Item>
          </>
        );
      default:
        return null;
    }
  }
}

export default CustomNavbar;
