import React from "react";

function Linkedin() {
  return (
    <div className="p-8 py-12 mt-16 max-w-full w-full overflow-hidden">
      <iframe
        src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/25456219"
        frameBorder="0"
        width="100%"
        height="1000"
        title="LinkedIn Page Posts"
      />
    </div>
  );
}

export default Linkedin;
