import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import backgroundVideo from "../../assets/video/home_video_2.mp4"; // Update the path as needed

function VideoText() {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: leftBoxRef, inView: leftBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: rightBoxRef, inView: rightBoxInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen overflow-hidden">
      {/* Background Video */}
      <video
        className="absolute inset-0 object-cover w-full h-full"
        src={backgroundVideo}
        autoPlay
        loop
        muted
      />

      {/* Overlay */}
      <div
        className="absolute inset-0"
        style={{ backgroundColor: "#284b5a", opacity: 0.7 }}
      ></div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center text-center text-white max-w-screen-xl mx-auto px-4 py-8">
        {/* Heading */}
        <motion.h2
          ref={headingRef}
          className="w-full text-3xl font-bold md:text-3xl"
          initial={{ y: -100, opacity: 0 }}
          animate={headingInView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.6 }}
        >
          DE KRACHT VAN DE JUISTE MENSEN OP DE JUISTE PLEK
        </motion.h2>

        {/* Boxes */}
        <div className="self-center mt-10 w-full md:mt-24">
          <div className="flex flex-col gap-5 md:flex-row md:gap-5">
            {/* Left Box */}
            <motion.div
              ref={leftBoxRef}
              className="flex flex-col w-full md:w-[50%]"
              initial={{ x: -100, opacity: 0 }}
              animate={leftBoxInView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 0.6 }}
            >
              <div
                className="grow px-5 py-10 text-lg text-white bg-zinc-300 bg-opacity-70 md:px-12 md:py-14 md:text-xl"
                style={{ backgroundColor: "#284b5a", opacity: 0.7 }}
              >
                <p>
                  Voelt u zich geroepen om samen met ons die stap te willen
                  maken en een stempel te zetten op de (nabije) toekomst, meld
                  je bij ons aan middels het inschrijfformulier en upload uw CV.
                </p>
              </div>
            </motion.div>
            {/* Right Box */}
            <motion.div
              ref={rightBoxRef}
              className="flex flex-col w-full md:w-[50%]"
              initial={{ x: 100, opacity: 0 }}
              animate={rightBoxInView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 0.6 }}
            >
              <div
                className="grow px-5 pt-8 pb-14 text-lg bg-zinc-300 bg-opacity-70 md:px-12 md:pt-11 md:pb-20 md:text-xl"
                style={{ backgroundColor: "#284b5a", opacity: 0.7 }}
              >
                <p>
                  Samen bekijken we dan welke kant we opgaan. Zeker als u
                  gebruik wilt maken van onze extra’s, zoals het huren van ons
                  materieel, onze administratieve ondersteuning en onze
                  opleidingen.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoText;
