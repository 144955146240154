import React from "react";
import { FaDownload } from "react-icons/fa"; // Importing an icon from react-icons

function Button({ fileHref }) {
  // fileHref is the prop for the file URL
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileHref; // Using the fileHref prop
    link.download = fileHref.split("/").pop(); // Extracts the filename from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return React.createElement(
    "div",
    { className: "flex justify-center items-center py-12" },
    React.createElement(
      "button",
      {
        onClick: handleDownload,
        className:
          "flex items-center px-6 py-3 bg-[#284B5A] text-white font-semibold rounded-lg shadow-md cursor-pointer",
      },
      "Klik hier voor meer informatie", // Button text
      React.createElement(FaDownload, {
        className: "ml-2 w-5 h-5", // Icon styling
      })
    )
  );
}

export default Button;
