import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import Welcome from "../../components/Welcome";
import ImageText1 from "../../components/ImageText1-1";
import ImageText2 from "../../components/ImageText2-1";
import Footer from "../../components/Footer";

import videoSource from "../../assets/video/scheepvaart_video.mp4";
import imageUrl from "../../assets/images/scheepvaart_image1.png";
import imageUrl2 from "../../assets/images/scheepvaart_image2.png";

function BouwInfra() {
  const welcomeHeading = "SAMEN OP WEG";
  const welcomeText =
    "Wij willen zowel de werknemer als de opdrachtgever boeien en langdurig binden door middel van zeer interessante deals voor iedereen persoonlijk of bedrijfsmatig afgestemd. Uw voordeel is ook ons voordeel, wij doen er alles aan om een win-win situatie te creëren.";

  const imageTextHeading = "Interesse in een functie in de scheepvaartsector?";
  const imageTextText = (
    <>
      Bent u geïnteresseerd in een mooie functie in de scheepvaart en heeft u
      voldoende ervaring opgebouwd of wilt u juist ervaring opbouwen?
      <br />
      <br />
      Dan zijn wij naar u op zoek! Wij bieden u de mogelijkheid om via ons
      tewerkgesteld te worden bij diverse gerenommeerde organisaties binnen de
      scheepvaartsector.
      <br />
      <br />
      Heeft u interesse?
    </>
  );

  const buttonText1 = "Neem Contact Met Ons Op";
  const buttonLink1 = "/Inschrijven"; // Update with your actual link

  const imageTextHeading2 = "Wat betekent het";
  const imageTextText2 = (
    <>
      Of u nu als service engineer, inspecteur, lasser of rigger, maar ook als
      werktuigkundige, scheepstimmerman, of projectleider werkt of wilt gaan
      werken,
      <br />
      <br />
      Na ontvangst van uw gegevens neemt één van onze medewerkers contact met u
      op voor een eerste kennismakingsgesprek.
      <br />
      <br />
      Als het duidelijk is in welke richting u het liefst werk zoekt gaan wij
      voor u aan de slag.
      <br />
      <br />
      aarzel niet en reageer vandaag nog! Het enige wat u hoeft te doen is u aan
      te melden via onze site en uw CV te uploaden.
    </>
  );

  const buttonText2 = "Cv Uploaden";
  const buttonLink2 = "/Inschrijven"; // Update with your actual link

  const colorbackground2 = "#D9D9D9";
  const colortext2 = "text-[#284B5A]";

  return (
    <div>
      <Helmet>
        <title>Scheepvaart</title>
        <meta
          name="description"
          content="Op zoek naar een baan in de scheepvaart? Of je nu ervaring hebt of net begint, wij koppelen je aan toonaangevende bedrijven. Solliciteer vandaag nog en ontwikkel je carrière."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <Welcome
          videoSource={videoSource}
          heading={welcomeHeading}
          text={welcomeText}
        />
        <section>
          <ImageText1
            imageUrl={imageUrl}
            heading={imageTextHeading}
            buttonText={buttonText1} // Make sure the prop names match
            buttonLink={buttonLink1} // Pass buttonLink if needed
            text={imageTextText}
          />
          <ImageText2
            imageUrl={imageUrl2}
            heading={imageTextHeading2}
            buttonText={buttonText2} // Make sure the prop names match
            buttonLink={buttonLink2} // Pass buttonLink if needed
            text1={imageTextText2}
            background={colorbackground2}
            textColor={colortext2}
          />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default BouwInfra;
