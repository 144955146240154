import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
import WelcomeImage1 from "../../components/WelcomeImage1";
import TextImage from "./TextImage";
import ImageCollage from "./ImageCollage1";
import Cards from "./Cards";
import Footer from "../../components/Footer";

import backgroundImage from "../../assets/images/Maatschappelijk Verantwoord Ondernemen & Sponsoring Background Image.png";
import imageUrl from "../../assets/images/MAATSCHAPPELIJK VERANTWOORD ONDERNEMEN & SPONSORING 1.png";

function MaatschappelijkVerantwoordOndernemenSponsoring() {
  const welcomeHeading = (
    <>MAATSCHAPPELIJK VERANTWOORD ONDERNEMEN & SPONSORING</>
  );

  const ImageHeading = <>MAATSCHAPPELIJK VERANTWOORD ONDERNEMEN & SPONSORING</>;
  const imagetext = (
    <>
      De DD-Group ondersteunt het "Tripple P" principe. People planet profit
      (ook wel: de drie P's) is een term uit de duurzame ontwikkeling. Het staat
      voor de drie elementen people (mensen), planet (planeet/milieu) en profit
      (opbrengst/winst, markt), die op harmonieuze wijze gecombineerd zouden
      moeten worden. De term werd in 1994 bedacht door John Elkington, een
      adviseur op het terrein van duurzame ontwikkeling.
      <br />
      <br />
      Het is van belang dat de drie pijlers met elkaar zoveel mogelijk in balans
      zijn. Wanneer bijvoorbeeld alleen naar winst wordt gekeken zullen mens en
      milieu hiervan de dupe worden,bijvoorbeeld door slechte
      arbeidsomstandigheden of vernietiging van de natuur. Maar het winstoogmerk
      blijft ook essentieel om voortgang te blijven garanderen.
    </>
  );
  return (
    <div>
      <Helmet>
        <title>MVO/Sponsoring</title>
        <meta
          name="description"
          content="Krijg training met DD-Group over veiligheid, brandpreventie en cybersecurity via Play-IT. Voor interne transport- en veiligheidstrainingen kun je contact met ons opnemen, en wij verbinden je met 'The Knowledge' voor het beste aanbod via onze speciale referentie."
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main>
        <WelcomeImage1
          backgroundImage={backgroundImage}
          heading={welcomeHeading}
        />
        <section>
          <TextImage
            heading={ImageHeading}
            text={imagetext}
            imageUrl={imageUrl}
          />
          <Cards />
          <ImageCollage />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default MaatschappelijkVerantwoordOndernemenSponsoring;
